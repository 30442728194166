import React from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';
import classNames from 'classnames';

import './Checkbox.scss';

function Checkbox(props) {
    const { className, ...rest } = props;
    return <MuiCheckbox className={classNames(className)} {...rest} />;
}

export default Checkbox;
