import React from 'react';
import { TextField as MuiTextField } from '@mui/material';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import './TextField.scss';

function TextField(props) {
    const { name, className, errors, ...rest } = props;
    return (
        <>
            <MuiTextField
                className={classNames('text-size', className)}
                name={name}
                size="small"
                variant="outlined"
                {...rest}
            />
            {!!errors && (
                <ErrorMessage className="error-message" errors={errors} name={name} as="p" />
            )}
        </>
    );
}

export default TextField;
