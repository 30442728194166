import React from "react";
import { TextField as MuiTextField, MenuItem } from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import "./Select.scss";

function Select(props) {
  const { className, options, optionComponent, ...rest } = props;
  return (
    <>
      <MuiTextField
        select
        size="small"
        variant="outlined"
        className={classNames("select-size", className)}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem
            disabled={option.disabled}
            key={option.value}
            value={option.id}
          >
            {optionComponent ? optionComponent(option) : option.name}
          </MenuItem>
        ))}
      </MuiTextField>
    </>
  );
}

export default Select;
