import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  CircularProgress,
} from "components/Mui/index.js";
import CheckboxesTags from "../CheckboxesTags/CheckboxesTags.jsx";
import moment from "moment";
import { find } from "lodash";
import { useTranslation } from "react-i18next";

import "./CustomAppBar.scss";

const CustomAppBar = ({
  title,
  textPrimary,
  textSecondary,
  handleCloseButton,
  handlePrimaryButton,
  handleBackButton,
  showPrimaryButton,
  showSecondaryButton,
  showBackButton,
  date,
  handleDateChange,
  dateOptions: dateOptionsProp,
  submitting,
  formId,
  message,
  children,
  className = "",
}) => {
  const [dateOptions, setDateOptions] = useState(dateOptionsProp || []);
  const { t } = useTranslation();

  useEffect(() => {
    if (handleDateChange) {
      const dateOptions = dateOptionsProp;
      const selectedDateOption =
        find(dateOptions, { id: moment().format("yyyy") }) || dateOptions[0];
      handleDateChange(selectedDateOption);
      setDateOptions(dateOptions);
    }
  }, [dateOptionsProp]);

  return (
    <>
      <div className={`col-12 custom-app-bar ${className}`} color="inherit">
        {title && <h5 className="col my-2 ellipsis">{title}</h5>}
        {!!dateOptions && date && (
          <CheckboxesTags
            size="small"
            disableClearable
            name="date"
            onChange={(e, value) => handleDateChange(value)}
            labelField="name"
            fullWidth={true}
            options={dateOptions}
            value={find(dateOptions, date) || date}
            id="date"
          />
        )}
        {showBackButton && (
          <Button
            className="px-4 me-3"
            onClick={handleBackButton}
            variant="contained"
          >
            {t("back")}
          </Button>
        )}
        {showSecondaryButton && (
          <Button
            className="px-4 me-3"
            onClick={handleCloseButton}
            variant="outlined"
          >
            {textSecondary ? textSecondary : "Close"}
          </Button>
        )}
        {showPrimaryButton && (
          <Button
            type="submit"
            disabled={submitting}
            form={formId}
            className="px-4 ms-0"
            variant="contained"
            onClick={handlePrimaryButton}
          >
            {submitting ? (
              <CircularProgress size={24} color="inherit" />
            ) : textPrimary ? (
              textPrimary
            ) : (
              `${t("Save")}`
            )}
          </Button>
        )}
        {children}
      </div>
      {message && (
        <div className="error-message top-note">
          {t(message.word, message.data)}
        </div>
      )}
    </>
  );
};
export default CustomAppBar;
