// // i18n.js
// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import HttpApi from "i18next-http-backend";
// // Import translations
// import enTranslation from "./locales/en.json";
// import arTranslation from "./locales/ar.json";

// i18n
//   .use(initReactI18next)
//   .use(LanguageDetector)
//   .init({
//     resources: {
//       en: {
//         translation: enTranslation,
//       },
//       ar: {
//         translation: arTranslation,
//       },
//     },
//     lng: "ar", // Default language
//     fallbackLng: "ar", // Fallback language
//     interpolation: {
//       escapeValue: false, // React already does escaping
//     },
//     detection: {
//       order: [
//         "queryString",
//         "cookie",
//         "localStorage",
//         "navigator",
//         "htmlTag",
//         "path",
//         "subdomain",
//       ],
//       caches: ["cookie", "localStorage"],
//     },
//     react: {
//       useSuspense: true,
//     },
//   });

// export default i18n;
// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
// Import translations
import enTranslation from "./locales/en.json";
import arTranslation from "./locales/ar.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      ar: {
        translation: arTranslation,
      },
    },
    fallbackLng: "ar", // Default fallback language
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["cookie", "localStorage"],
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
