import React, { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Switch } from "react-router-dom";
import PublicLayout from "./layouts/PublicLayout/PublicLayout";
// import PrivateLayout from './layouts/PrivateLayout/PrivateLayout';
// import AdminLayout from './layouts/AdminLayout/AdminLayout';
import { Loading } from "./components/Mui/index.js";
import AdminLayout from "layouts/AdminLayout/AdminLayout.jsx";
import PrivateLayout from "layouts/PrivateLayout/PrivateLayout.jsx";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import PublicLayoutPrivacy from "layouts/PublicLayout/PublicLayoutPrivacy";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { isArabic } from "utils/utils";
import { getIsLoggedIn } from "utility/helpers/authHelper";

const NotFound = React.lazy(() => import("./containers/NotFound/NotFound.jsx"));
const ComingSoon = React.lazy(() =>
  import("./containers/ComingSoon/ComingSoon.jsx")
);
const Login = React.lazy(() => import("./containers/Login/Login.jsx"));
const PrivacyPolicy = React.lazy(() =>
  import("./containers/PrivacyPolicy/PrivacyPolicy.jsx")
);
const Register = React.lazy(() => import("./containers/SignUp/SignUp.jsx"));

const Home = React.lazy(() => import("./containers/Alerts/Alerts.jsx"));
const Dashboard = React.lazy(() =>
  import("./containers/Dashboard/Dashboard.jsx")
);
const Contact = React.lazy(() => import("./containers/Contact/Contact.jsx"));
const Events = React.lazy(() => import("./containers/Events/Events.jsx"));
const Companies = React.lazy(() =>
  import("./containers/Companies/Companies.jsx")
);
const Reviews = React.lazy(() => import("./containers/Reviews/Reviews.jsx"));
const Users = React.lazy(() => import("./containers/Users/Users.jsx"));
const PostDetails = React.lazy(() =>
  import("./containers/PostDetails/PostDetails.jsx")
);
const Categories = React.lazy(() =>
  import("./containers/Categories/Categories.jsx")
);
const UserProfile = React.lazy(() =>
  import("./containers/UserProfile/UserProfile.jsx")
);
const CreateDocument = React.lazy(() =>
  import("./containers/CreateDocument/CreateDocument")
);

const ResetPassword = React.lazy(() =>
  import("./containers/ResetPassword/ResetPassword.jsx")
);
const ForgetPassword = React.lazy(() =>
  import("./containers/ForgetPassword/ForgetPassword.jsx")
);
const ConfirmRegistration = React.lazy(() =>
  import("./containers/ConfirmRegistration/ConfirmRegistration.jsx")
);

const App = () => {
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const cacheLtr = createCache({
    key: "muiltr",
  });

  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", isArabic() ? "rtl" : "ltr");
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <CacheProvider value={isArabic() ? cacheRtl : cacheLtr}>
        <Header />
        <Suspense fallback={<Loading />}>
          <Switch>
            <PublicLayout
              enableRedirect={true}
              path="/login"
              component={Login}
            ></PublicLayout>
            <PublicLayout
              exact
              enableRedirect={true}
              path="/register"
              component={Register}
            ></PublicLayout>
            <PublicLayout
              exact
              path="/forget-password"
              component={ForgetPassword}
            ></PublicLayout>
            <PublicLayoutPrivacy
              exact
              path="/privacy-policy"
              component={PrivacyPolicy}
            ></PublicLayoutPrivacy>
            <PublicLayout
              exact
              path="/reset-password"
              component={ResetPassword}
            ></PublicLayout>
            <PublicLayout
              exact
              path="/confirm-registration"
              component={ConfirmRegistration}
            ></PublicLayout>

            <PrivateLayout exact path="/" component={Categories} />
            <AdminLayout exact path="/dashboard" component={Dashboard} />
            <PrivateLayout exact path="/contact" component={Contact} />
            <PrivateLayout exact path="/post" component={PostDetails} />
            <PrivateLayout exact path="/alerts" component={Home} />
            <PrivateLayout exact path="/profile" component={UserProfile} />
            <AdminLayout
              exact
              path="/create-document"
              component={CreateDocument}
            />
            <AdminLayout exact path="/users" component={Users} />
            <PrivateLayout exact path="/companies" component={Companies} />
            <AdminLayout exact path="/reviews" component={Reviews} />
            <PrivateLayout exact path="/events" component={Events} />
            <PublicLayout exact path="/coming-soon" component={ComingSoon} />
            <PublicLayout className="logged-in" component={NotFound} />
          </Switch>
        </Suspense>
      </CacheProvider>
    </I18nextProvider>
  );
};

export default App;
