import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { get } from "lodash";
import {
  Snackbar,
  TextField,
  DialogActions,
  Button,
  TextArea,
  Select,
} from "components/Mui/index.js";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import { addCert } from "utility/apis/ReviewService";
import { Controller } from "react-hook-form/dist/index.js";
import { getUserId, getUserInfoObject } from "utility/helpers/authHelper.jsx";

import "./CertForm.scss";
import Rating from "@mui/material/Rating/index.js";
import { Typography } from "@mui/material/index.js";
import { toNumber } from "lodash/lang.js";
import { useTranslation } from "react-i18next";

const CertForm = (props) => {
  const { title, toggleCertModal, message } = props;
  const [alertStatus, setAlertStatus] = useState(undefined);
  const { t } = useTranslation();
  const [certDetails, setCertDetails] = useState({});
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
  });
  const user_info = getUserInfoObject();

  const handleSaveCertButton = async (data) => {
    try {
      console.log(user_info);
      console.log({ ...data, registeredEmail: user_info.email });
      await addCert({ ...data, registeredEmail: user_info.email });
      setAlertStatus({
        open: true,
        message: "Application Sent Successfully",
        severity: "success",
      });
      toggleCertModal();
    } catch (e) {
      setAlertStatus({
        open: true,
        message:
          e.response?.data?.errData?.message ??
          "Error while sending application",
        severity: "error",
      });
    }
  };

  return (
    <div id="personal-info" className="c-personal-info mx-4">
      <div className="container-fluid">
        <form onSubmit={handleSubmit(handleSaveCertButton)}>
          <CustomAppBar
            title={title}
            showSaveButton
            handleCloseButton={toggleCertModal}
            message={message}
          />
          <div className="row">
            <div className="col-12">
              <TextField
                id="certification"
                name="certification"
                label={t("certificationName")}
                type="text"
                defaultValue={get(certDetails, "title", "")}
                inputRef={register({
                  required: t("this_field_is_required"),
                })}
                errors={errors}
              />
            </div>
            <div className="col-12">
              <Controller
                id="description"
                name="description"
                control={control}
                mode="onBlur"
                inputRef={register({
                  required: t("this_field_is_required"),
                })}
                defaultValue={get(document, "description", "")}
                as={
                  <TextArea
                    label={t("description")}
                    className="w-full"
                    type="text"
                    minRows={6}
                    errors={errors}
                  />
                }
              />
            </div>
          </div>
          <div>
            <DialogActions className="p-3 mt-3">
              <Button
                className="px-4 me-3"
                variant="outlined"
                onClick={toggleCertModal}
              >
                {t("cancel")}
              </Button>
              <Button className="px-4" variant="contained" type="submit">
                {t("save")}
              </Button>
            </DialogActions>
          </div>
          {!!alertStatus && (
            <Snackbar
              message={alertStatus.message}
              severity={alertStatus.severity}
              open={alertStatus.open}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default CertForm;
