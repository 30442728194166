import moment from "moment";

export const setToken = (token) => window.localStorage.setItem("jwt", token);
export const setExpiryTime = (expiryTime) =>
  window.localStorage.setItem("expiryTime", expiryTime);
export const setRefreshToken = (refreshToken) =>
  window.localStorage.setItem("refreshToken", refreshToken);
export const setUserInfo = (userInfo) =>
  window.localStorage.setItem("userInfo", JSON.stringify(userInfo));

export const getToken = () => window.localStorage.getItem("jwt");
export const getExpiryTime = () => window.localStorage.getItem("expiryTime");
export const getUserInfo = () => window.localStorage.getItem("userInfo");
export const getUserInfoObject = () =>
  JSON.parse(window.localStorage.getItem("userInfo") || "");
export const getRefreshToken = () =>
  window.localStorage.getItem("refreshToken");

export const setAuthData = (authData) => {
  const { token, token_expires_in, refresh_token, user } = authData;
  setToken(token);
  setExpiryTime(token_expires_in);
  setRefreshToken(refresh_token);
  setUserInfo(user);
};

export const getIsLoggedIn = () => {
  const token = getToken();
  return token && !isTokenExpired();
};

export const getIsAdmin = () => {
  const userInfo = JSON.parse(getUserInfo() || "{}");
  return userInfo.type === "admin";
};

export const getUserId = () => {
  const userInfo = JSON.parse(getUserInfo() || "{}");
  return userInfo.id;
};

export const clearAuth = (history) => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("expiryTime");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("userInfo");
  localStorage.clear();
  history.push("/");
};

export const isTokenExpired = () => {
  try {
    const expiryTime = getExpiryTime();
    return parseInt(expiryTime) < moment().valueOf();
  } catch (err) {
    return false;
  }
};
