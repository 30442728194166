import React from "react";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedInIcon,
  GoogleIcon,
} from "components/Mui/Icons.js";

export const attendance_white_listed_options = [
  { value: "NO", label: "No" },
  { value: "MANAGER", label: "Manager" },
  { value: "PART_TIMER", label: "Part Timer" },
  { value: "REMOTE_WORKING", label: "Remote Worker" },
  { value: "OTHER", label: "Other" },
];

export const how_it_works_carousel = [
  {
    titleKey: "marketing-video",
    imageName: "marketing-video.png",
    videoName: "PSC -marketing-video.mp4",
  },
  {
    titleKey: "how-it-works-1",
    imageName: "how-it-works-1.png",
  },
  {
    titleKey: "how-it-works-2",
    imageName: "how-it-works-2.png",
  },
  {
    titleKey: "how-it-works-3",
    imageName: "how-it-works-3.png",
  },
  {
    titleKey: "how-it-works-4",
    imageName: "how-it-works-4.png",
  },
  {
    titleKey: "how-it-works-5",
    imageName: "how-it-works-5.png",
  },
  {
    titleKey: "how-it-works-6",
    imageName: "how-it-works-6.png",
  },
  {
    titleKey: "how-it-works-7",
    imageName: "how-it-works-7.png",
  },
  {
    titleKey: "how-it-works-8",
    imageName: "how-it-works-8.png",
  },
];

export const features = [
  {
    title: "Natural language",
    imageName: "feature-1-icon.png",
    description: "PSC is your friendly virtual HR assistant",
    fullDescription:
      "PSC will answer your questions and guide you in taking actions quickly and efficiently",
  },
  {
    title: "Multiple platforms",
    imageName: "feature-2-icon.png",
    description: "PSC is available on Slack and via the web",
    fullDescription: "Facebook Workplace integration is coming soon",
  },
  {
    title: "Alexa Integration",
    imageName: "feature-3-icon.png",
    description: "Coming soon",
    fullDescription:
      "PSC will be available via Alexa and provide meeting coordination/setup and other productivity boosting virtual office assistant features",
  },
  {
    title: "PSC clock",
    imageName: "feature-4-icon.png",
    description: "Coming soon",
    fullDescription: "PSC clock will manage employee clocking in/out of work",
  },
];

export const clients = [
  {
    title: "PSC ",
    imageName: "clients-1-logo.png",
  },
  {
    title: "Intertech",
    imageName: "clients-2-logo.png",
  },
];

export const review_of_customers = [
  {
    title: "PSC ",
    review:
      "Our teams all use Slack, so having our time-off system available through PSC has been a productivity boost for our employees and managers",
    imageName: "clients-1-logo.png",
  },
  {
    title: "Intertech",
    review:
      "Our teams all use Slack, so having our time-off system available through PSC has been a productivity boost for our employees and managers",
    imageName: "clients-2-logo.png",
  },
];

export const social_links = [
  {
    title: "Facebook",
    url: "https://www.facebook.com/",
    icon: <FacebookIcon />,
  },
  {
    title: "Twitter",
    url: "https://twitter.com/AiTalk2",
    icon: <TwitterIcon />,
  },
  {
    title: "Linkedin",
    url: "https://www.linkedin.com/company/17915938/",
    icon: <LinkedInIcon />,
  },
  {
    title: "Talk2ai",
    url: "http://talk2ai.blogspot.com/",
    icon: <GoogleIcon />,
  },
];

export const yesNoOptions = [
  { label: "Yes", value: true, id: true },
  { label: "No", value: false, id: false },
];

export const colorList = [
  { name: "color_1", color: "#01599e", darker: "#0e99a1", lighter: "#f7f9fa" },
  {
    name: "color_2",
    color: "#66A0BF",
    darker: "#3d779a",
    lighter: "#66A0BF0d",
  },
  {
    name: "color_3",
    color: "#27AE60",
    darker: "#1d874d",
    lighter: "#27ae600d",
  },
  {
    name: "color_4",
    color: "#2980B9",
    darker: "#1d669b",
    lighter: "#2980B90d",
  },
  {
    name: "color_5",
    color: "#8E44AD",
    darker: "#6a3492",
    lighter: "#8E44AD0d",
  },
  {
    name: "color_6",
    color: "#2C3E50",
    darker: "#1f2a35",
    lighter: "#2C3E500d",
  },
  {
    name: "color_7",
    color: "#D35400",
    darker: "#a03500",
    lighter: "#D354000d",
  },
  {
    name: "color_8",
    color: "#7F8C8D",
    darker: "#606c6e",
    lighter: "#7F8C8D0d",
  },
];
