/* eslint-disable no-use-before-define */

import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import { TextField, Checkbox, Chip, Autocomplete } from 'components/Mui/index.js';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from 'components/Mui/Icons';
import profilePicture from 'assets/images/profile.svg';

import './CheckboxesTags.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags(props) {
  const {
    labelField,
    name,
    label,
    placeholder,
    showLabel,
    showLeftImage,
    hideSelectedItems,
    fullWidth,
    inputRef,
    multiple,
    loading,
    errors,
    ...rest
  } = props;

  return (
    <div>
      {showLabel && label && (
        <label className="pl-1 font-weight-bold label-tag u-color-darkish-gray mr-5">
          {label}
        </label>
      )}
      <Autocomplete
        inputRef={inputRef}
        loading={loading}
        name={name}
        multiple={multiple}
        className={'checkboxes-tags'}
        {...rest}
        getOptionLabel={(option) => get(option, labelField)}
        renderOption={(props, option, status) => (
          <li
            {...props}
            className={classNames(
              'd-flex option my-1',
              option.fixed && 'disabled',
              status.selected && 'selected'
            )}
          >
            <Checkbox
              className={classNames('', !multiple && 'visibility-hidden')}
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={
                multiple
                  ? status.selected
                  : status.inputValue === option[labelField] ||
                    status.inputValue === option['value']
              }
            />
            {showLeftImage &&
              (option.profilePicture ? (
                <img
                  className="profile-pic-left"
                  alt="img"
                  src={option.profilePicture}
                />
              ) : (
                <img
                  className="profile-pic-left"
                  alt="defaultImg"
                  src={profilePicture}
                />
              ))}
            <div className="col mx-3">
              <div className="row">{get(option, labelField)}</div>
              {option?.scondaryText && (
                <div className="row secondaryText">{option?.scondaryText}</div>
              )}
            </div>
          </li>
        )}
        renderTags={(tagValues, getTagProps) => {
          if (hideSelectedItems && tagValues && tagValues.length > 1) {
            return (
              <div
                className="text-nowrap"
                style={{
                  paddingTop: '9.5px',
                }}
              >{`${tagValues.length} ${label} selected`}</div>
            );
          } else {
            return tagValues.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                disabled={option.fixed}
                label={get(option, labelField)}
              />
            ));
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            inputRef={inputRef}
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder}
          />
        )}
      />
      {!!errors && (
        <ErrorMessage
          className="error-message"
          errors={errors}
          name={name}
          as="p"
        />
      )}
    </div>
  );
}
