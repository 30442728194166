import React from "react";
import classNames from "classnames";
import { Route, Redirect } from "react-router-dom";
import { getIsLoggedIn } from "utility/helpers/authHelper.jsx";
import { ThemeProvider } from "components/Mui/index.js";
import { defaultTheme } from "theme";
import "./PublicLayout.scss";
const PublicLayoutPrivacy = ({
  component: Component,
  className = "not-logged-in",
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <ThemeProvider theme={defaultTheme}>
            <div className={classNames("c-layout", className)}>
              <main className="c-public-layout c-layout_main container-fluid background-style">
                <Component {...rest} {...matchProps} />
              </main>
            </div>
          </ThemeProvider>
        </>
      )}
    />
  );
};

export default PublicLayoutPrivacy;
