import React from "react";
import classNames from "classnames";
import { Route, Redirect } from "react-router-dom";
import { getIsLoggedIn } from "utility/helpers/authHelper.jsx";
import { ThemeProvider } from "components/Mui/index.js";
import { defaultTheme } from "theme";
import "./PublicLayout.scss";
import Footer from "components/Footer/Footer";
const PublicLayout = ({
  component: Component,
  className = "not-logged-in",
  ...rest
}) => {
  const isLoggedIn = getIsLoggedIn();
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          {isLoggedIn ? (
            <Redirect to={{ pathname: "/" }} />
          ) : (
            <ThemeProvider theme={defaultTheme}>
              <div className={classNames("c-layout", className)}>
                <main className="c-public-layout c-layout_main container-fluid background-style">
                  <Component {...rest} {...matchProps} />
                </main>
              </div>
              <Footer term={getIsLoggedIn()} />
            </ThemeProvider>
          )}
        </>
      )}
    />
  );
};

export default PublicLayout;
