import API from "./ApiBase.jsx";
import { API_URL } from "utility/constants/APIConst.jsx";

export const login = async (payload) => {
  return await API.post("/api/v1/auth/login", payload);
};

export const logout = async () => {
  return await API.delete("/api/v1/auth/logout");
};

export const deleteMyAccount = async () => {
  return await API.delete("/api/v1/auth/me");
};

export const signUp = async (payload) => {
  return API.post("/api/v1/auth/register", payload);
};

export const uploadLoge = async (id, payload) => {
  return API.post(`/api/v1/${id}/logo`, payload); // multipart/form-data
};

export const updateUserDetails = async (id, payload) => {
  return API.put(`${API_URL.USER_LIST}/${id}`, {
    ...payload,
    secondaryPhoneNumber: payload.secondaryPhoneNumber.replace(/\s+/g, ""),
    phoneNumber: payload.phoneNumber.replace(/\s+/g, ""),
  }); // multipart/form-data
};

export const updateUserAdmin = async (id, payload) => {
  return API.put(`${API_URL.USER_LIST}/${id}`, {
    ...payload,
  }); // multipart/form-data
};

export const deleteUser = async (id) => {
  return API.delete(`${API_URL.USER_LIST}/${id}`);
};

export const getCities = async () => {
  return API.get(API_URL.CITIES_LIST);
};
export const forgetPassword = (payload) => {
  return API.post(API_URL.FORGET, payload);
};

export const resetPassword = (payload) => {
  return API.post(API_URL.RESET, payload);
};

export const validateRegistration = (payload) => {
  return API.post(API_URL.VERIFY, payload);
};

export const contact = (payload) => {
  return API.post(API_URL.CONTACT, payload);
};

export const updateUserProfile = async (payload) => {
  return API.put(`${API_URL.PROFILE}`, {
    ...payload,
    secondaryPhoneNumber: payload.secondaryPhoneNumber.replace(/\s+/g, ""),
    phoneNumber: payload.phoneNumber.replace(/\s+/g, ""),
  });
};

export const getUserProfile = async () => {
  return API.get(`${API_URL.PROFILE}`);
};
