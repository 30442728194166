import React from "react";
import classNames from "classnames";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DATE_FORMAT } from "utility/helpers/metaData";
import { TextField } from "components/Mui";

import "./DatePicker.scss";
import { ErrorMessage } from "@hookform/error-message";

function DateTimePicker(props) {
  const { className, errors, name, ...rest } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDateTimePicker
        slots={{
          textField: (textFieldProps) => (
            <TextField {...textFieldProps} name={name} {...rest} />
          ),
        }}
        className={classNames(className)}
        name={name}
        {...rest}
      />
      {!!errors && (
        <ErrorMessage
          className="error-message"
          errors={errors}
          name={name}
          as="p"
        />
      )}
    </LocalizationProvider>
  );
}

export default DateTimePicker;
