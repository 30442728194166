import React, { useState, useEffect } from 'react';
import { Snackbar as MuiSnackbar } from '@mui/material';
import { Alert, Slide } from 'components/Mui/index.js';
import classNames from 'classnames';

import './Snackbar.scss';

function Snackbar(props) {
    const { message, severity, open, className, ...rest } = props;
    const [openStatus, setOpenStatus] = useState(false);

    useEffect(() => {
        setOpenStatus(open);
    }, [open]);

    const handleClose = () => {
        setOpenStatus(false);
    };

    const SlideTransition = (props) => {
        return <Slide {...props} direction="up" />;
    };

    return (
        <MuiSnackbar
            className={classNames(className)}
            TransitionComponent={SlideTransition}
            open={openStatus}
            autoHideDuration={5000}
            onClose={handleClose}
            {...rest}
        >
            <Alert elevation={6} variant="filled" onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </MuiSnackbar>
    );
}

export default Snackbar;
