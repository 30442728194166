// LanguageSwitcher.jsx
import React from "react";
import i18n from "../../i18n";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { isArabic } from "utils/utils";
const LanguageSwitcher = () => {
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    // Set a cookie or localStorage item for the chosen language
    document.cookie = `i18next=${lng};path=/`;
    localStorage.setItem("i18nextLng", lng);
    // Set the dir attribute based on the language
    document.body.style.direction = lng === "ar" ? "rtl" : "ltr";

    // Reload the page to ensure the language change is applied
    window.location.reload();
  };

  return (
    <div>
      <div className="switcher-buttons">
        <Button
          onClick={() => changeLanguage("en")}
          variant="contained"
          color="primary"
        >
          {t("English")}
        </Button>
        <Button
          onClick={() => changeLanguage("ar")}
          variant="contained"
          color="primary"
        >
          {t("Arabic")}
        </Button>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
