import React from 'react';
import { Divider as MuiDivider } from '@mui/material';
import classNames from 'classnames';

import './Divider.scss';

function Divider(props) {
    const { className, ...rest } = props;
    return <MuiDivider className={classNames(className)} {...rest} />;
}

export default Divider;
