import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { get } from "lodash";
import {
  Snackbar,
  TextField,
  DialogActions,
  Button,
  TextArea,
  Select,
} from "components/Mui/index.js";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import { addReview, getUserReview } from "utility/apis/ReviewService";
import { Controller } from "react-hook-form/dist/index.js";
import { getUserId } from "utility/helpers/authHelper.jsx";

import "./ReviewForm.scss";
import Rating from "@mui/material/Rating/index.js";
import { Typography } from "@mui/material/index.js";
import { toNumber } from "lodash/lang.js";
import { useTranslation } from "react-i18next";

const ReviewForm = (props) => {
  const { title, toggleReviewModal, message } = props;
  const [alertStatus, setAlertStatus] = useState(undefined);
  const { t } = useTranslation();
  const [reviewDetails, setReviewDetails] = useState({});
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
  });
  let fetchData = async () => {
    try {
      const review = await getUserReview(getUserId());
      setReviewDetails(review.data.data);
    } catch (e) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveReviewButton = async (data) => {
    try {
      data.rate = toNumber(data.rate);
      await addReview(data);
      setAlertStatus({
        open: true,
        message: t("saved_successfully"),
        severity: "success",
      });
    } catch (e) {
      setAlertStatus({
        open: true,
        message:
          e.response?.data?.errData?.message ?? "Error while saving review",
        severity: "error",
      });
    }
  };

  return (
    <div id="personal-info" className="c-personal-info mx-4">
      <div className="container-fluid">
        <form onSubmit={handleSubmit(handleSaveReviewButton)}>
          <CustomAppBar
            title={title}
            showSaveButton
            handleCloseButton={toggleReviewModal}
            message={message}
          />
          <div className="row">
            <div className="col-12">
              <TextField
                id="title"
                name="title"
                label={t("title")}
                type="text"
                defaultValue={get(reviewDetails, "title", "")}
                inputRef={register({
                  required: t("this_field_is_required"),
                })}
                errors={errors}
              />
            </div>
            <div className="col-12">
              <Controller
                id="description"
                name="description"
                control={control}
                mode="onBlur"
                inputRef={register({
                  required: t("this_field_is_required"),
                })}
                defaultValue={get(document, "description", "")}
                as={
                  <TextArea
                    label={t("description")}
                    className="w-full"
                    type="text"
                    minRows={6}
                    errors={errors}
                  />
                }
              />
            </div>
            <div className="col-12">
              <Typography variant="body2" color="text.secondary">
                {t("rate")}
              </Typography>
              <Controller
                as={<Rating name="read-only" value={0} />}
                id="rate"
                control={control}
                inputRef={register({
                  required: t("this_field_is_required"),
                })}
                mode="onBlur"
                name="rate"
                defaultValue={get(reviewDetails, "rate", 0)}
              />
            </div>
          </div>
          <div>
            <DialogActions className="p-3 mt-3">
              <Button
                className="px-4 me-3"
                variant="outlined"
                onClick={toggleReviewModal}
              >
                {t("cancel")}
              </Button>
              <Button className="px-4" variant="contained" type="submit">
                {t("save")}
              </Button>
            </DialogActions>
          </div>
          {!!alertStatus && (
            <Snackbar
              message={alertStatus.message}
              severity={alertStatus.severity}
              open={alertStatus.open}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default ReviewForm;
