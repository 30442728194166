export const PERSONAL_INFO_MODAL = "PERSONAL_INFO_MODAL";
export const Delete_ACCOUNT_MODAL = "Delete_ACCOUNT_MODAL";
export const SHARE_MODAL = "SHARE_MODAL";
export const EDIT_USER_MODAL = "EDIT_USER_MODAL";
export const USER_INFO_MODAL = "USER_INFO_MODAL";
export const COMPANY_INFO_MODAL = "COMPANY_INFO_MODAL";
export const EVENT_INFO_MODAL = "EVENT_INFO_MODAL";
export const EVENT_ATTENDEES_MODAL = "EVENT_ATTENDEES_MODAL";
export const REVIEWS_MODAL = "REVIEWS_MODAL";
export const CERT_MODAL = "CERT_MODAL";
export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const CERTIFICATE_MODAL = "CERTIFICATE_MODAL";
export const SETTINGS_MODAL = "SETTINGS_MODAL";
