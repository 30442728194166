import React, { createContext, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import classNames from "classnames";
import { ThemeProvider } from "components/Mui/index.js";
import { getIsLoggedIn } from "utility/helpers/authHelper.jsx";
import { colorList } from "utility/constants/data.jsx";
import { customTheme } from "theme";

import "./PrivateLayout.scss";
import LeftNavigation from "components/LeftNavigation/LeftNavigation.jsx";
import { isArabic } from "utils/utils";
import Footer from "components/Footer/Footer";

export const ThemeColorContext = createContext();
const PrivateLayout = ({
  component: Component,
  className = "logged-in",
  ...rest
}) => {
  const isLoggedIn = getIsLoggedIn();
  const themeColor = "color_1"; // save color in localstorage
  const [themeInfo] = useState(
    colorList.find((item) => item.name === themeColor) || colorList[0]
  );
  const [selectedTheme, setSelectedTheme] = useState(themeInfo);
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (!isLoggedIn) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: matchProps.location } }}
            />
          );
        }
        // authorised so return component
        return (
          <ThemeColorContext.Provider value={{ setSelectedTheme }}>
            <ThemeProvider theme={customTheme(selectedTheme)}>
              <div className={classNames("c-layout", className)}>
                <LeftNavigation></LeftNavigation>
                <main
                  className={`c-private-layout c-layout_main ${
                    isArabic() ? "c-private-layout-ar" : "c-private-layout-en"
                  }`}
                  style={{
                    // backgroundColor: selectedTheme?.lighter,
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Component {...rest} {...matchProps} />
                </main>
              </div>
              <Footer term={getIsLoggedIn()} />
            </ThemeProvider>
          </ThemeColorContext.Provider>
        );
      }}
    />
  );
};

export default PrivateLayout;
