import axios from 'axios';
import ENVIRONMENT from '../config.js';
import { clearAuth, getToken } from '../helpers/authHelper.jsx';

export const API_URL = ENVIRONMENT.ENV_IP;

const handleErrors = async (err) => {
    if (err.response) {
        if (err.response.status === 401) {
            clearAuth();
        }
    }

    return Promise.reject(err);
};

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: `${API_URL}`,
    timeout: 300000
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (err) => Promise.reject(err)
);

axiosInstance.interceptors.response.use(
    (response) =>
        // Do something with response data
        response,
    handleErrors
);

export default axiosInstance;
