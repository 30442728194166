import React from 'react';
import { Link as MuiLink } from '@mui/material';
import classNames from 'classnames';

import './Link.scss';

function Link(props) {
    const { children, className, ...rest } = props;
    return (
        <MuiLink className={classNames(className)} {...rest}>
            {children}
        </MuiLink>
    );
}

export default Link;
