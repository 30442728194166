import React from 'react';
import { Chip as MuiChip } from '@mui/material';
import classNames from 'classnames';

import './Chip.scss';

function Chip(props) {
    const { className, ...rest } = props;
    return <MuiChip className={classNames(className)} {...rest} />;
}

export default Chip;
