import { createTheme } from "components/Mui/index.js";
import { isArabic } from "utils/utils.js";

const customTheme = (userTheme) => {
  return createTheme({
    direction: isArabic() ? "rtl" : "ltr",
    palette: {
      type: "light",
      primary: {
        light: "#f2f8f8",
        main: userTheme?.color,
        dark: userTheme?.darker,
        contrastText: "#fff",
      },
      secondary: {
        light: "#fff",
        main: "#fff",
        dark: "#fff",
        contrastText: userTheme?.lighter,
      },
    },
  });
};
const defaultTheme = createTheme({
  direction: "rtl",
  palette: {
    type: "light",
    primary: {
      light: "#f2f8f8",
      main: "#01599e",
      dark: "#1c86ee",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff",
      main: "#fff",
      dark: "#fff",
      contrastText: "#01599e",
    },
  },
});

export { defaultTheme, customTheme };
