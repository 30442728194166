import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom/esm/react-router-dom.js";
import { getAllCategories } from "utility/apis/DocumentService.jsx";
import Box from "@mui/material/Box/index.js";
import List from "@mui/material/List/index.js";
import ListItem from "@mui/material/ListItem/index.js";
import ListItemButton from "@mui/material/ListItemButton/index.js";
import Typography from "@mui/material/Typography/index.js";
import ListItemText from "@mui/material/ListItemText/index.js";
import Divider from "@mui/material/Divider/index.js";
import { LeftDrawer } from "components/Mui/index.js";
import { useTranslation } from "react-i18next";
import "./LeftNavigation.scss";
import LogoImage from "assets/images/logo.png";
import { isArabic } from "utils/utils";

const LeftNavigation = () => {
  const { t } = useTranslation();
  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    // setInProgress(true);
    try {
      const categories = await getAllCategories();
      setCategoriesList(categories.data.data);
    } catch (e) {
      // setAlertStatus({
      //     open: true,
      //     message: "Error while fetching categories",
      //     severity: "error",
      // });
    }
    // setInProgress(false);
  };

  return (
    <div
      className={`left-navigation  ${
        isArabic() ? "left-navigation-ar" : "left-navigation-en"
      }  d-none d-xl-flex`}
    >
      <LeftDrawer>
        <div className="logo-container d-flex align-items-center flex-column py-5">
          <Link to="/">
            {/* <img src={LogoImage} alt="PSC" className="logo me-2 mt-1" /> */}
            <span
              className="text-capitalize fw-bold title-header"
              style={{
                color: "#01599e !important",
                fontSize: isArabic() ? "25px" : "20px",
              }}
            >
              {t("header.title2")}
            </span>
            <br />
            <span
              className="text-capitalize fw-bold subtitle-header"
              style={{
                color: "#01599e !important",
                fontSize: isArabic() ? "22px" : "18px",
              }}
            >
              {t("header.subtitle")}
            </span>
          </Link>
        </div>
        <Box sx={{ width: 250 }} role="presentation">
          <List>
            <ListItem
              key="latestAlerts"
              style={{ minHeight: "112px" }}
              component={Link}
              to={"/alerts?latest=1"}
              disablePadding
            >
              <ListItemButton>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: isArabic() ? "25px" : "20px",
                        textWrap: "wrap",
                        marginTop: "8px",
                        textAlign: isArabic() ? "right" : "left",
                      }}
                    >
                      {t("last_alerts")}

                      <br />
                      <span className="cat-desc">
                        {/* {
                              item[
                                isArabic() ? "description_ar" : "description_en"
                              ]
                            } */}
                      </span>
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider className="pt-0" />
          <List className="categories-list">
            {categoriesList &&
              categoriesList.map((item, index) => (
                <ListItem
                  key={item.id}
                  component={Link}
                  style={{ minHeight: "112px" }}
                  to={`alerts?category=${item.id}`}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText
                      // style={{ fontSize: "25px" }}
                      primary={
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: isArabic() ? "25px" : "20px",
                            textWrap: "wrap",
                            textAlign: isArabic() ? "right" : "left",
                          }}
                        >
                          {item[isArabic() ? "name_ar" : "name_en"]}
                          <br />
                          <span className="cat-desc">
                            {
                              item[
                                isArabic() ? "description_ar" : "description_en"
                              ]
                            }
                          </span>
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}

            <ListItem
              key="latestAlerts"
              // component={Link}
              // to={"/alerts"}

              disablePadding
            >
              <ListItemButton>
                <ListItemText
                  primary={
                    <a href="https://portal.psc.ps/" target="_blank">
                      <Typography
                        variant="body1"
                        style={{
                          fontSize: isArabic() ? "25px" : "20px",
                          marginTop: "8px",
                          textWrap: "wrap",
                          textAlign: isArabic() ? "right" : "left",
                        }}
                      >
                        {t("PSCTrade")}
                      </Typography>
                    </a>
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </LeftDrawer>
    </div>
  );
};

export default LeftNavigation;
