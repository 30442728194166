import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import LogoImage from "assets/images/logo.png";
import { Dialog } from "components/Mui/index.js";
import ReviewForm from "containers/ReviewForm/ReviewForm.jsx";
import withModals from "hoc/withModals/withModals.jsx";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom"; // Assuming you're using React Router
import { deleteMyAccount, logout } from "utility/apis/UserService";
import {
  clearAuth,
  getIsAdmin,
  getIsLoggedIn,
} from "utility/helpers/authHelper";
import {
  CERT_MODAL,
  REVIEWS_MODAL,
  CONFIRMATION_MODAL,
} from "utility/modals/constants.jsx";
import "./Header.scss";
import MobileDrawer from "./MobileDrawer/MobileDrawer";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { isArabic } from "utils/utils";
import CertForm from "containers/CertForm/CertForm";
import Footer from "components/Footer/Footer";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";

const menuLinks = [
  { text: "header.home", link: "/" },
  { text: "header.reviews", link: "/reviews", justAdmin: true },
  { text: "header.dashboard", link: "/dashboard", justAdmin: true },
  { text: "header.users", link: "/users", justAdmin: true },
  { text: "contact.contactus", link: "/contact", justUser: true },
  { text: "header.companies", link: "/companies", justAdmin: true },
  { text: "header.events", link: "/events" },
];

const Header = (props) => {
  let { toggleReviewModal, toggleCertModal, modals, toggleDeleteAccountModal } =
    props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoggedIn = getIsLoggedIn();
  const isAdmin = getIsAdmin();
  const { t } = useTranslation();

  const [term, setTerm] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeLink, setActiveLink] = React.useState("");
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  useEffect(() => {
    const searchKeyword = urlParams.get("search");
    setTerm(searchKeyword);
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMyAccount = async () => {
    try {
      toggleDeleteAccountModal();
      await deleteMyAccount();
      clearAuth(history);
    } catch (e) {}
  };

  const handleLogout = async () => {
    try {
      await logout();
      clearAuth(history);
    } catch (e) {}
  };

  const handleReview = async () => {
    toggleReviewModal();
  };

  const handleCert = async () => {
    toggleCertModal();
  };
  const handleProfile = () => {
    history.push("/profile");
  };

  const handleContact = () => {
    history.push("/contact");
  };

  const handleCompany = () => {
    history.push("/companies");
  };

  const searchData = async (e) => {
    history.push(`/alerts?search=${term}`);
    console.log("search about " + term);
  };

  const accountLinks = [
    { text: "header.profile", onClick: handleProfile },
    { text: "company", link: "/", onClick: handleCompany, justUser: true },
    { text: "header.applyForCertificate", link: "/", onClick: handleCert },
    {
      text: "header.deleteMyAccount",
      link: "/",
      onClick: toggleDeleteAccountModal,
    },
    { text: "header.logout", link: "/", onClick: handleLogout },
  ];
  return (
    <AppBar className={`header ${!isArabic() && "header-en"}`}>
      <Toolbar
        className={`header-content ${
          isArabic() ? "header-content-ar" : "header-content-en"
        }`}
      >
        {/* Logo */}
        <div className="logo-container logo-header align-items-center">
          <Link to="/">
            {/* <img src={LogoImage} alt="PSC" className="logo me-2 mt-1" /> */}
            <span className="text-uppercase fw-bold title-header fs-5 mx-2 title-mobile">
              {t("header.title2")}
            </span>
          </Link>
        </div>
        <LanguageSwitcher />

        {isLoggedIn && (
          <div className="search-cont" style={{ order: isMobile ? "5" : "0" }}>
            <div
              style={{
                width: isMobile ? "100%" : "80%",
                position: "relative",

                padding: "12px",
                margin: "auto",
              }}
            >
              <input
                type="text"
                placeholder={t("header.search")}
                className="search-input"
                defaultValue={term}
                onChange={(e) => {
                  setTerm(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searchData(e);
                  }
                }}
                style={{
                  width: "100%",
                  padding: "8px 32px 8px 48px",
                  borderRadius: "20px",
                  border: `1px solid `,
                  backgroundColor: alpha(theme.palette.common.white, 0.1),
                  color: "inherit",
                  transition: "width 0.3s",
                }}
              />

              <button
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: "20px",
                  cursor: "pointer",
                  color: "#fff",
                  border: 0,
                  background: "inherit",
                }}
                onClick={searchData}
              >
                <SearchIcon />
              </button>
            </div>
          </div>
        )}
        {!isMobile && isLoggedIn && (
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="header-menu-items"
          >
            {menuLinks
              .filter((item) => {
                if (getIsAdmin()) {
                  return !item.justUser;
                } else {
                  return !item.justAdmin;
                }
              })
              .map((link, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  component={Link}
                  to={link.link}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    fontWeight:
                      location && location.pathname === link.link
                        ? "700"
                        : "normal", // Custom color for active link
                    fontSize: "20px",
                    marginLeft: "16px",
                  }}
                  onClick={() => setActiveLink(link.text)}
                >
                  {t(link.text)}
                </Typography>
              ))}
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="large"
                style={{ color: "white" }}
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <AccountCircleOutlinedIcon style={{ fontSize: "2rem" }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleProfile}>{t("header.profile")}</MenuItem>
              {!getIsAdmin() && (
                <MenuItem onClick={handleCompany}>{t("company")}</MenuItem>
              )}
              <MenuItem onClick={handleCert}>
                {t("header.applyForCertificate")}
              </MenuItem>

              <MenuItem onClick={toggleDeleteAccountModal}>
                {t("header.deleteMyAccount")}
              </MenuItem>
              <MenuItem onClick={handleLogout}>{t("header.logout")}</MenuItem>
            </Menu>
          </div>
        )}

        {/* Menu Button for Mobile */}
        {isMobile && isLoggedIn && (
          <>
            <MobileDrawer accountLinks={accountLinks} links={menuLinks} />
          </>
        )}

        <Dialog
          className="event-attendees-details-dialog"
          mainTitle={t("header.applyForCertificate")}
          open={modals[CERT_MODAL]}
          toggle={toggleCertModal}
        >
          <CertForm toggleCertModal={toggleCertModal}></CertForm>
        </Dialog>

        <Dialog
          className="details-dialog"
          mainTitle={t("header.deleteMyAccount")}
          open={modals[CONFIRMATION_MODAL]}
          toggle={toggleDeleteAccountModal}
        >
          <ConfirmationDialog
            handleCloseButton={toggleDeleteAccountModal}
            modals={modals}
            handleDeleteAction={handleDeleteMyAccount}
          ></ConfirmationDialog>
        </Dialog>
      </Toolbar>
    </AppBar>
  );
};

const modalsProps = {
  toggleCertModal: CERT_MODAL,
  toggleReviewModal: REVIEWS_MODAL,
  toggleDeleteAccountModal: CONFIRMATION_MODAL,
};

export default withModals(Header, modalsProps);
