import API from "./ApiBase.jsx";
import { API_URL } from "utility/constants/APIConst.jsx";

// TODO: Move these 2 later
export const addCompany = async (payload) => {
  return API.put(API_URL.REVIEWS_LIST, payload);
};

export const addCert = async (payload) => {
  return API.post(`${API_URL.CERT_LIST}`, payload);
};

export const addReview = async (payload) => {
  return API.post(`${API_URL.REVIEWS_LIST}`, payload);
};

export const getAllReviews = async () => {
  return API.get(API_URL.REVIEWS_LIST);
};

export const getUserReview = async (id) => {
  return API.get(`${API_URL.REVIEWS_LIST}/${id}`);
};
