import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid as MuiDataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
} from "components/Mui";
import { getDataTable } from "utility/apis/ConfigService";

import "./DataGridTable.scss";

function DataGridTable(props) {
  // const [queryOptions, setQueryOptions] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [rows, setRows] = useState([]);
  const {
    isExport,
    columnsButton,
    columnVisibilityModel,
    isLoading,
    URL,
    formatDataTable,
    onDataTableChanged,
    data,
    dataTable,
    ...rest
  } = props;

  const onFilterChange = useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    // setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  useEffect(() => {
    if (URL) {
      fetchData();
    }
  }, [URL]);

  useEffect(() => {
    if (dataTable) {
      setRows(dataTable);
    }
  }, [dataTable]);

  const fetchData = async () => {
    setInProgress(true);
    try {
      if (URL) {
        try {
          const response = await getDataTable(URL);
          let { data } = response;

          if (onDataTableChanged) {
            onDataTableChanged(data);
          }

          if (formatDataTable) {
            data = formatDataTable(data);
          }
          setRows(data);
        } catch (e) {}
      }
    } catch (e) {}
    setInProgress(false);
  };

  const onPageSizeChange = (pageSize, details) => {};

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  };

  return (
    <div className="c-data-grid" style={{ height: "100%", width: "100%" }}>
      <MuiDataGrid
        // filterMode="server"
        onFilterModelChange={onFilterChange}
        loading={inProgress}
        // slots={{
        //     // toolbar: CustomToolbar
        //     toolbar: columnsButton ? CustomToolbar : undefined
        // }}
        rows={rows}
        initialState={{
          columns: {
            columnVisibilityModel,
          },
        }}
        onPaginationModelChange={onPageSizeChange}
        autoPageSize={true}
        {...rest}
      />
    </div>
  );
}

export default DataGridTable;
