import React from "react";
import { Button, Dialog, DialogActions } from "components/Mui/index.js";

import { CONFIRMATION_MODAL } from "utility/modals/constants.jsx";
import withModals from "hoc/withModals/withModals.jsx";
import "./ConfirmationDialog.scss";
import { useTranslation } from "react-i18next";
const ConfirmationDialog = (props) => {
  const {
    modals,
    handleCloseButton,
    toggleConfirmationModal,
    handleDeleteAction,
  } = props;

  const { t } = useTranslation();

  return (
    <Dialog
      className="confirmation-dialog"
      open={modals[CONFIRMATION_MODAL]}
      toggle={toggleConfirmationModal}
    >
      <div>
        <div className="m-4 u-color-primary">{t("sure_to_delete")}</div>
        <div>
          <DialogActions className="p-3 mt-3">
            <Button
              className="px-4 me-3"
              variant="outlined"
              onClick={handleCloseButton}
            >
              {t("cancel")}
            </Button>
            <Button
              className="px-4 bg-danger"
              color="secondary"
              variant="outlined"
              onClick={handleDeleteAction}
            >
              {t("delete")}
            </Button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

const modalsProps = {
  toggleConfirmationModal: CONFIRMATION_MODAL,
};

export default withModals(ConfirmationDialog, modalsProps);
