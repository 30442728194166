import React from 'react';
import { TextareaAutosize as MuiTextarea } from '@mui/base/TextareaAutosize';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import './TextArea.scss';
import {InputLabel} from "@mui/material";

function TextArea(props) {
    const { name, className, errors, label, ...rest } = props;
    return (
        <>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <MuiTextarea
                className={classNames('text-size', className)}
                name={name}
                variant="outlined"
                {...rest}
            />
            {!!errors && (
                <ErrorMessage className="error-message" errors={errors} name={name} as="p" />
            )}
        </>
    );
}

export default TextArea;
