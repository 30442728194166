import React from "react";
import { Route, Redirect } from "react-router-dom";
import classNames from "classnames";
import { getIsLoggedIn, getIsAdmin } from "utility/helpers/authHelper.jsx";
import { ThemeProvider } from "components/Mui/index.js";
import { defaultTheme } from "theme";
import LeftNavigation from "components/LeftNavigation/LeftNavigation.jsx";
import "./AdminLayout.scss";
import { isArabic } from "utils/utils";
import Footer from "components/Footer/Footer";

const AdminLayout = ({
  component: Component,
  className = "logged-in",
  ...rest
}) => {
  const isLoggedIn = getIsLoggedIn() && getIsAdmin();
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: matchProps.location } }}
            />
          );
        }
        return (
          <ThemeProvider theme={defaultTheme}>
            <div className={classNames("c-layout", className)}>
              <LeftNavigation></LeftNavigation>
              <main
                className={`c-admin-layout c-layout_main background-style ${
                  isArabic() ? "c-private-layout-ar" : "c-private-layout-en"
                }`}
              >
                <Component {...rest} {...matchProps} />
              </main>
            </div>
            <Footer term={getIsLoggedIn()} />
          </ThemeProvider>
        );
      }}
    />
  );
};

export default AdminLayout;
