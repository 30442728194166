import moment from "moment/moment.js";
import i18n from "../i18n.js";

export const isArabic = () => {
  const currentLang = i18n.language || window.localStorage.i18nextLng;
  return currentLang === "ar";
};

export const parseCustomDateString = (dateString) => {
  // Step 1: Remove special characters
  const cleanDateString = dateString.replace(/[^\x20-\x7E]/g, "");

  // Step 2: Use moment to parse the cleaned string
  const parsedDate = moment(cleanDateString, "MM/DD/YYYY hh:mm A");

  // Step 3: Return the JavaScript Date object
  return parsedDate.toISOString();
};
