import React, { useEffect, useState } from "react";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import { isArabic } from "utils/utils";
import LogoImage from "assets/images/logo.png";
import usaidLogo from "assets/images/usaidlogo.png";
import { getIsLoggedIn } from "utility/helpers/authHelper";
import { Box, Button, Dialog } from "components/Mui/index";
import { REVIEWS_MODAL } from "utility/modals/constants.jsx";
import withModals from "hoc/withModals/withModals";
import ReviewForm from "containers/ReviewForm/ReviewForm";

const Footer = (props) => {
  const { toggleReviewModal, modals } = props;
  const { t } = useTranslation();

  const handleReview = async () => {
    toggleReviewModal();
  };
  return (
    <>
      <Box className="footer text-center py-4 shadow-sm">
        <div
          className={` 
        ${
          getIsLoggedIn() && (isArabic() ? "description-ar" : "description-en")
        } description`}
        >
          {t("footer.text")}
          <div className="d-flex justify-content-center mt-3 align-items-center">
            <img
              src={usaidLogo}
              alt="USAID"
              className="logo me-2 mt-1"
              style={{ height: "90px", width: "200px" }}
            />
            <img src={LogoImage} alt="PSC" className="logo me-2 mt-1" />
          </div>
          {getIsLoggedIn() && (
            <Button onClick={() => handleReview()} className="fs-5">
              {t("header.review")}
            </Button>
          )}
        </div>
      </Box>
      <Dialog
        className="event-attendees-details-dialog"
        mainTitle={t("header.review")}
        open={modals[REVIEWS_MODAL]}
        toggle={toggleReviewModal}
      >
        <ReviewForm toggleReviewModal={toggleReviewModal}></ReviewForm>
      </Dialog>
    </>
  );
};

const modalsProps = {
  toggleReviewModal: REVIEWS_MODAL,
};

export default withModals(Footer, modalsProps);
