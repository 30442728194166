import React from "react";
import { CircularProgress } from "../index.js";

import "./Loading.scss";

const Loading = (props) => {
  const { containerClassName } = props;
  return (
    <div className={`loading mt-5 ${containerClassName ?? ""}`}>
      <CircularProgress size={100} />
    </div>
  );
};
export default Loading;
